/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import s from './s.module.less';
import { EServiceConfigPriceType, EServiceType, KlarityService, KlarityServicePrice, KlarityServiceTypeTemplate, KlarityServiceTypeValue, ServerKlarityService, ServerMeta, TKlarityStateTPrice } from 'types/common';
import ConfigServiceType from './components/ConfigServiceType';
import { HELP_EMAIL } from 'constants/common';
import { Button, Select, Spin } from 'antd';
import useKlarityServicePrice from 'hooks/useKlarityServicePrice';
import { getKlarityPriceAreaFromRecommendPrice } from 'utils/service';
import { ServerData } from 'components/ApplicationFormComponents/KlarityServices/types';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import { PracitceAddressForm } from 'types/practiceFront';

type IProps = {
    serviceDatas?: KlarityService[];
    serverIdStateMap: Record<string, ServerMeta[]>;
    currentEditingStep: number;
    editingValue?: ServerKlarityService[];
    isEdit?: boolean;
    onUpdate?: (data: ServerData) => void;
    onHasNameErrorUpdate?: (hasError: boolean) => void;
    isOps?: boolean;
    providerId?: number;
    addressList?: PracitceAddressForm[];
    allStatesFromService?: ServerMeta[];
};

const ConfigService = (props: IProps) => {
    const { serviceDatas = [], addressList, serverIdStateMap, currentEditingStep, onUpdate, onHasNameErrorUpdate, editingValue, isEdit, isOps, providerId, allStatesFromService } = props;
    const [inxStep, setInxStep] = React.useState(currentEditingStep || 1);
    const [allStates] = useGetSettingData(EnumFields.STATE);

    const [pendingAddServiceType, setPendingAddServiceType] = React.useState<string>();
    const [addedServiceTypes, setAddedServiceTypes] = React.useState<KlarityServiceTypeTemplate[]>([]);

    const pendingServerKlarityServices = React.useRef<ServerKlarityService[]>([]);
    const [pendingServerKlarityServicesFlag, setPendingServerKlarityServicesFlag] = React.useState({});
    const pendingDisabledIds = React.useRef<boolean[]>([]);
    const [pendingDisabledIdsFlag, setPendingDisabledIdsFlag] = React.useState({});

    const [allowStateFromEdit, setAllowStateFromEdit] = React.useState<ServerMeta[]>();
    const [stateFromInitVisit, setStateFromInitVisit] = React.useState<ServerMeta[]>();

    const nameIsExistedError = React.useRef<boolean[]>([]);

    const { length } = serviceDatas;
    const width = `${((inxStep - 1) / length) * 100}%`;

    const showProgress = serviceDatas.length > 1;

    const current = serviceDatas.length >= inxStep ? serviceDatas[inxStep - 1] : undefined;
    const optionServiceTypes = current?.serversTypeNames || [];
    //const allConfigingServiceTypes:KlarityServiceTypeTemplate[] = [...(current?.serversTypeNames?.filter((item) => item.serviceType === EServiceType.MEDICATION_REFILL) || []), ...addedServiceTypes];
    const [allConfigingServiceTypes, setAllConfigingServiceTypes] = React.useState<KlarityServiceTypeTemplate[]>([...(current?.serversTypeNames || []), ...addedServiceTypes]);

    const allItemLength = allConfigingServiceTypes.length || 0;
    const addLength = addedServiceTypes.length || 0;

    React.useEffect(() => {
        let _allConfigingServiceTypes: KlarityServiceTypeTemplate[] = [];
        if (isEdit && editingValue) {
            editingValue.forEach((item) => {
                const template = current?.serversTypeNames?.find((i) => i.serviceType === item.serviceType);
                if (template) {
                    _allConfigingServiceTypes.push(template);
                } else if (current?.serversTypeNames && current?.serversTypeNames?.length > 0) {
                    _allConfigingServiceTypes.push({ ...current?.serversTypeNames[0], displayName: 'Others' });
                }
            });
            //_allConfigingServiceTypes = _allConfigingServiceTypes.filter((item) => item.serviceType === EServiceType.ESTABLISHED_PATIENT_VISIT);
            _allConfigingServiceTypes = [..._allConfigingServiceTypes, ...addedServiceTypes];
        }

        if (current?.savedValue && current?.savedValue.length > 0) {
            current?.savedValue.forEach((item) => {
                const template = current?.serversTypeNames?.find((i) => i.serviceType === item.serviceType);
                if (template) {
                    _allConfigingServiceTypes.push(template);
                } else if (current?.serversTypeNames && current?.serversTypeNames?.length > 0) {
                    _allConfigingServiceTypes.push({ ...current?.serversTypeNames[0] });
                }
            });
            _allConfigingServiceTypes = [..._allConfigingServiceTypes, ...addedServiceTypes];
        }
        if (_allConfigingServiceTypes.length === 0) {
            _allConfigingServiceTypes = [...(current?.serversTypeNames || []), ...addedServiceTypes];
        }
        if (addedServiceTypes.length === 0) {
            pendingServerKlarityServices.current = pendingServerKlarityServices.current?.slice(0, _allConfigingServiceTypes.length);
        }
        setAllConfigingServiceTypes(_allConfigingServiceTypes);
    }, [current, addedServiceTypes, isEdit, editingValue]);

    const allowStates = serverIdStateMap[`${current?.id}`];

    let choosedStates = isEdit ? allowStateFromEdit : allowStates;
    if (isEdit && stateFromInitVisit && stateFromInitVisit.length > 0) {
        choosedStates = stateFromInitVisit;
    }

    const [allStateConfigTypePriceData, setAllStateConfigTypePriceData] = React.useState<Record<string, TKlarityStateTPrice>>();
    const [klarityServicePriceData, loading] = useKlarityServicePrice(current?.specialtyName, allStatesFromService?.map((item) => item.content), isOps, providerId);

    //console.log(JSON.stringify(klarityServicePriceData));

    React.useEffect(() => {
        if (editingValue) {
            const stateKeys: string[] = [];
            editingValue.forEach((item) => {
                item?.serviceStateItems?.forEach((stateItem) => {
                    if (!stateKeys.includes(stateItem.state)) {
                        stateKeys.push(stateItem.state);
                    }
                });
            });
            // pendingServerKlarityServices.current = [];
            // pendingDisabledIds.current = [];
            const statesFromEdit = (allStates as ServerMeta[])?.filter((state: ServerMeta) => stateKeys.includes(state.dictKey));
            setAllowStateFromEdit(statesFromEdit);
        }
    }, [editingValue]);

    React.useEffect(() => {
        if (currentEditingStep === inxStep) {
            return;
        }
        setInxStep(currentEditingStep);
        nameIsExistedError.current = [];
        pendingServerKlarityServices.current = [];
        pendingDisabledIds.current = [];
        setAddedServiceTypes([]);
    }, [currentEditingStep, inxStep]);

    const generatePrices = (v: number = 0, state?: string) => {
        const _statePriceMap: Record<string, TKlarityStateTPrice> = {};
        const allStateContent: string[] = [];
        const krcPriceItem: KlarityServicePrice[] = klarityServicePriceData?.[EServiceConfigPriceType.KRC] || [];
        const marketingMarkupPriceItem: KlarityServicePrice[] = klarityServicePriceData?.[EServiceConfigPriceType.MARKETING_MARKUP] || [];
        const platformFeePriceItem: KlarityServicePrice[] = klarityServicePriceData?.[EServiceConfigPriceType.PLATFORM_FEE] || [];
        krcPriceItem.forEach((item) => {
            if (!allStateContent.includes(item.state)) {
                allStateContent.push(item.state);
            }
        });
        allStateContent.forEach((stateContent: string) => {
            const floatPrice = state && stateContent === state ? v : 0;
            const data: TKlarityStateTPrice = {
                [EServiceType.INIT]: {},
                [EServiceType.CONSULTATION]: {},
                [EServiceType.ESTABLISHED_PATIENT_VISIT]: {},
                [EServiceType.MEDICATION_REFILL]: {},
                [EServiceType.OTHER_PATIENT_SUPPORT]: {},
                [EServiceType.CONSULTATION_OVERCHARGE]: {},
            };
            const krcPrice = krcPriceItem.find((item) => item.state === stateContent);
            data[EServiceType.INIT]!.krc = krcPrice?.newPatientInitialVisit || 0;
            data[EServiceType.CONSULTATION]!.krc = krcPrice?.consultation || 0;
            data[EServiceType.ESTABLISHED_PATIENT_VISIT]!.krc = krcPrice?.establishedPatientFollowUpVisit || 0;
            data[EServiceType.MEDICATION_REFILL]!.krc = krcPrice?.medicationRefill || 0;
            data[EServiceType.OTHER_PATIENT_SUPPORT]!.krc = krcPrice?.documentationSupport || 0;

            data[EServiceType.CONSULTATION_OVERCHARGE]!.krc = krcPrice?.consultation || 0;

            const mmuPrice = marketingMarkupPriceItem.find((item) => item.state === stateContent);
            data[EServiceType.INIT]!.mmu = mmuPrice?.newPatientInitialVisit || 0;
            data[EServiceType.CONSULTATION]!.mmu = mmuPrice?.consultation || 0;
            data[EServiceType.ESTABLISHED_PATIENT_VISIT]!.mmu = mmuPrice?.establishedPatientFollowUpVisit || 0;
            data[EServiceType.MEDICATION_REFILL]!.mmu = mmuPrice?.medicationRefill || 0;
            data[EServiceType.OTHER_PATIENT_SUPPORT]!.mmu = mmuPrice?.documentationSupport || 0;

            data[EServiceType.CONSULTATION_OVERCHARGE]!.mmu = mmuPrice?.consultation;

            const pfPrice = platformFeePriceItem.find((item) => item.state === stateContent);
            data[EServiceType.INIT]!.pf = pfPrice?.newPatientInitialVisit || 0;
            data[EServiceType.CONSULTATION]!.pf = pfPrice?.consultation || 0;
            data[EServiceType.ESTABLISHED_PATIENT_VISIT]!.pf = pfPrice?.establishedPatientFollowUpVisit || 0;
            data[EServiceType.MEDICATION_REFILL]!.pf = pfPrice?.medicationRefill || 0;
            data[EServiceType.OTHER_PATIENT_SUPPORT]!.pf = pfPrice?.documentationSupport || 0;

            data[EServiceType.CONSULTATION_OVERCHARGE]!.pf = pfPrice?.newPatientInitialVisit || 0;
            _statePriceMap[stateContent] = data;

            const initTotal = (krcPrice?.newPatientInitialVisit || 0) + floatPrice + (mmuPrice?.newPatientInitialVisit || 0) + (pfPrice?.newPatientInitialVisit || 0);
            const consultationTotal = (krcPrice?.consultation || 0) + floatPrice + (mmuPrice?.consultation || 0) + (pfPrice?.consultation || 0);
            const establishedTotal = (krcPrice?.establishedPatientFollowUpVisit || 0) + floatPrice + (mmuPrice?.establishedPatientFollowUpVisit || 0) + (pfPrice?.establishedPatientFollowUpVisit || 0);
            const medicationRefillTotal = (krcPrice?.medicationRefill || 0) + floatPrice + (mmuPrice?.medicationRefill || 0) + (pfPrice?.medicationRefill || 0);
            const documentationSupportTotal = (krcPrice?.documentationSupport || 0) + floatPrice + (mmuPrice?.documentationSupport || 0) + (pfPrice?.documentationSupport || 0);

            const consultationOverchargeTotal = (krcPrice?.consultation || 0) + floatPrice + (mmuPrice?.consultation || 0) + (pfPrice?.newPatientInitialVisit || 0);

            data[EServiceType.INIT]!.recommendPriceArea = getKlarityPriceAreaFromRecommendPrice(initTotal);
            data[EServiceType.CONSULTATION]!.recommendPriceArea = getKlarityPriceAreaFromRecommendPrice(consultationTotal);
            data[EServiceType.ESTABLISHED_PATIENT_VISIT]!.recommendPriceArea = getKlarityPriceAreaFromRecommendPrice(establishedTotal);
            data[EServiceType.MEDICATION_REFILL]!.recommendPriceArea = getKlarityPriceAreaFromRecommendPrice(medicationRefillTotal);
            data[EServiceType.OTHER_PATIENT_SUPPORT]!.recommendPriceArea = getKlarityPriceAreaFromRecommendPrice(documentationSupportTotal);

            data[EServiceType.CONSULTATION_OVERCHARGE]!.recommendPriceArea = getKlarityPriceAreaFromRecommendPrice(consultationOverchargeTotal);
        });
        setAllStateConfigTypePriceData(_statePriceMap);
    }

    React.useEffect(() => {
        generatePrices()
    }, [klarityServicePriceData]);

    React.useEffect(() => {
        const max = allConfigingServiceTypes.length;
        const disableIds = pendingDisabledIds.current.map((isDisabled, inx) => {
            if (isDisabled && inx < max) {
                if (pendingServerKlarityServices.current.length > inx && pendingServerKlarityServices.current[inx]) {
                    return pendingServerKlarityServices.current[inx].serviceId;
                }
            }
            return 0;
        }).filter(Boolean);
        const filteredPendingServerKlarityServices = pendingServerKlarityServices.current.filter((item, inx) => {
            if (pendingDisabledIds && pendingDisabledIds.current.length > inx) {
                if (pendingDisabledIds.current[inx]) {
                    return false;
                }
            }
            if (inx > max) {
                return false;
            }
            return true;
        });
        // console.log({
        //     max,
        //     pendingDisabledIds,
        //     disableIds,
        //     filteredPendingServerKlarityServices,
        //     pendingServerKlarityServices
        // })
        const data: ServerData = {
            providerKlarityService: filteredPendingServerKlarityServices,
            disableIds,
            allSeted: filteredPendingServerKlarityServices.length === max,
        };

        let hasEditingNameError = false;

        const allNames: string[] = [];
        filteredPendingServerKlarityServices.forEach((item: ServerKlarityService) => {
            const orgTemplate = current?.serversTypeNames?.find((i) => i.serviceType === item.serviceType);
            const name = `${item?.serviceSpecialtyName}${(item?.customizedServiceName || '')}${orgTemplate?.displayName}`;
            if (allNames.includes(name)) {
                hasEditingNameError = true;
            }
            allNames.push(name);
        });
        const hasServerNameError = nameIsExistedError.current.filter((val) => !!val).length > 0;
        onHasNameErrorUpdate?.(hasEditingNameError || hasServerNameError);
        onUpdate?.(data);
    }, [allConfigingServiceTypes.length, pendingServerKlarityServicesFlag, pendingDisabledIdsFlag, pendingAddServiceType]);

    const allNames: string[] = [];
    pendingServerKlarityServices.current.forEach((item: ServerKlarityService, inx) => {
        if (pendingDisabledIds.current.length > inx && pendingDisabledIds.current[inx]) {
            return;
        }
        const orgTemplate = current?.serversTypeNames?.find((i) => i.serviceType === item.serviceType);
        const name = `${item?.serviceSpecialtyName}${(item?.customizedServiceName || '')}${orgTemplate?.displayName}`;
        allNames.push(name);
    });

    const handleInitVisitStateChange = (states: ServerMeta[]) => {
        if (states && states.length > 0) {
            setStateFromInitVisit(states);
        }
    };

    if (isEdit && !editingValue) {
        return (
            <div className={s.wrap}>
                <Spin style={{
                    height: '500px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                />
            </div>
        );
    }
    return (
        <div className={s.wrap}>
            <div className={s.topBar}>
                <div className={s.left}>
                    {
                        showProgress &&
                        <>
                            <div className={s.text}>{inxStep - 1}/{serviceDatas.length} completed</div>
                            <div className={s.bar}>
                                <div className={s.barIn} style={{ width }} />
                            </div>
                        </>
                    }
                </div>
                <div className={s.tips}>
                    Need Help? Email <a href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</a>
                </div>
            </div>
            <div className={s.content}>
                <div className={s.title}>
                    <img src={current?.iconUrl} />
                    <span>{current?.specialtyName}</span>
                </div>
                <div className={s.itemWrap}>
                    {
                        allConfigingServiceTypes?.map((item: KlarityServiceTypeTemplate, inx) => {
                            const key = `${item.displayName + inx}`;
                            const value = current?.serversTypes?.find((i: KlarityServiceTypeValue) => {
                                return i.serviceTypeId === item.id;
                            });
                            let editValue: ServerKlarityService | undefined;
                            if (isEdit && editingValue && editingValue.length > inx) {
                                editValue = editingValue[inx];
                            }
                            if (current?.savedValue && current?.savedValue.length > inx) {
                                editValue = current?.savedValue[inx];
                            }
                            let defaultOpen = false;
                            if (inx === 0) {
                                defaultOpen = true;
                            }
                            if (!editValue && inx >= (current?.serversTypeNames?.length || 0)) {
                                defaultOpen = true;
                            }
                            const isAdd = allItemLength - inx <= addLength;
                            const isInit = item.serviceType === EServiceType.INIT;
                            return (
                                <div className={s.configTypeItem} key={`${key}_${current?.specialtyName}`}>
                                    <ConfigServiceType
                                        generatePrices={generatePrices}
                                        isOps={isOps}
                                        providerId={providerId}
                                        isAdd={isAdd}
                                        allStatesFromService={allStatesFromService}
                                        //todo need add from removed from service and in editing?
                                        allowStates={choosedStates}
                                        serviceType={item.serviceType}
                                        serviceTypeTemplate={item}
                                        serviceTypeTemplateValue={value}
                                        allStateConfigTypePriceData={allStateConfigTypePriceData}
                                        service={current}
                                        allEditingNames={allNames}
                                        onNameIsExistedError={(isExisted: boolean) => {
                                            nameIsExistedError.current[inx] = isExisted;
                                            const hasError = nameIsExistedError.current.filter((val) => !!val).length > 0;
                                            onHasNameErrorUpdate?.(!!hasError);
                                        }}
                                        defaultOpen={defaultOpen || isAdd}
                                        onServiceTypeInfoUpdate={(info: ServerKlarityService) => {
                                            pendingServerKlarityServices.current[inx] = info;
                                            setPendingServerKlarityServicesFlag({});
                                        }}
                                        onDisabled={(disabled: boolean) => {

                                            pendingDisabledIds.current[inx] = disabled;
                                            setPendingDisabledIdsFlag({});
                                        }}
                                        editValue={editValue}
                                        onRemove={() => {
                                            const deleteInx = inx - (allConfigingServiceTypes.length - addedServiceTypes.length);
                                            const _addedServiceTypes = [...addedServiceTypes];
                                            _addedServiceTypes.splice(deleteInx, 1);
                                            delete pendingServerKlarityServices.current[inx];
                                            delete pendingDisabledIds.current[inx];
                                            setPendingDisabledIdsFlag({});
                                            setAddedServiceTypes(_addedServiceTypes);
                                        }}
                                        addressList={addressList}
                                        onInitVisitStateChange={handleInitVisitStateChange}
                                    />
                                </div>
                            );
                        })
                    }
                    <div className={s.addNew}>
                        <div className={s.left}>
                            <div className={s.label}>
                                Add another service as
                            </div>
                            <div className={s.selectWrap}>
                                <Select
                                    value={pendingAddServiceType}
                                    className={s.select}
                                    onChange={setPendingAddServiceType}
                                >
                                    {
                                        optionServiceTypes?.map((val: KlarityServiceTypeTemplate) => {
                                            return (
                                                <Select.Option key={val.serviceType} value={val.serviceType}>
                                                    {val.displayName || val.serviceType}
                                                </Select.Option>
                                            );
                                        })
                                    }
                                </Select>
                            </div>
                        </div>
                        <div className={s.addBtn}>
                            <Button
                                onClick={() => {
                                    if (pendingAddServiceType) {
                                        const addTemplate: KlarityServiceTypeTemplate | undefined = optionServiceTypes.find((item) => item.serviceType === pendingAddServiceType);
                                        if (addTemplate) {
                                            const _newAdded: KlarityServiceTypeTemplate[] = [...addedServiceTypes];
                                            _newAdded.push(addTemplate);
                                            setAddedServiceTypes(_newAdded);
                                            setPendingAddServiceType(optionServiceTypes?.[0]?.serviceType);
                                            pendingDisabledIds.current[allConfigingServiceTypes.length] = false;
                                        }
                                        setPendingAddServiceType(undefined);
                                    }
                                }}
                                className={s.btn}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path d="M8.50033 3.63672V14.3034M13.8337 8.97005L3.16699 8.97005" stroke="#111827" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>Add</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfigService;
